import CryptoJS from 'crypto-js';

export function getQueryParam(paramName) {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    let cbParam = urlParams.get(paramName);
    return cbParam;
}

const SECRET_KEY = process.env.REACT_APP_SECRET_KEY;
// Helper function to generate security headers
export const generateSecurityHeaders = () => {
    const timestamp = new Date().toUTCString();
    const signature = CryptoJS.HmacSHA256(timestamp, SECRET_KEY).toString();
    
    return {
      'X-Timestamp': timestamp,
      'X-Signature': signature
    };
  };

  // Helper function to create a secure axios config
export const createSecureAxiosConfig = (url, method = 'get', data = null) => {
    const config = {
      method: method,
      url: url,
      headers: generateSecurityHeaders(),
    };
    
    if (data) {
      config.data = data;
    }
    
    return config;
  };

