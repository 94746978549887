import React from "react";
import { useState, useEffect, useContext } from "react";
import axios from "axios";
import { GlobalContext } from "../../../mycontext";
import "./Natal.css";
import { useTranslation } from "react-i18next";
import ImageMapper from "../ImageMapper.json";
import { createSecureAxiosConfig } from "../../utils/utility";

const RashiMapper = {
  Aqu: "Aquarius",
  Ari: "Aries",
  Can: "Cancer",
  Cap: "Capricorn",
  Gem: "Gemini",
  Leo: "Leo",
  Lib: "Libra",
  Pis: "Pisces",
  Sag: "Sagittarius",
  Sco: "Scorpio",
  Tau: "Taurus",
  Vir: "Virgo",
};

function extract_image_link(name) {
  let imglink = "/assets/Planets/";
  return imglink + ImageMapper[name] + ".png";
}

function extract_image_link_nakshatra(name) {
  let imglink = "/assets/Nakshatra/";
  // console.log("iconsssss", name);
  
  return imglink + ImageMapper[name] + ".svg";
}

const noncombust = ["Ascendant", "Sun", "Rahu", "Ketu"];
const NatalSidetable = (props) => {
  const { t, i18n } = useTranslation();
  const contextType = useContext(GlobalContext);
  const [data, setData] = useState([]);
  useEffect(() => {
    (async () => {
      let y = "";
      let m = "";
      let d = "";
      let h = "";
      let mi = "";
      let s = "";

      if (props.newTime == null) {
        h = "00";
        mi = "00";
        s = "00";
      } else {
        var newTime = props.newTime.split(":");
        h = newTime[0];
        mi = newTime[1];
        s = newTime[2];
      }

      if (props.value !== null) {
        y = props.value.getFullYear();
        m = props.value.getMonth() + 1;
        d = props.value.getDate();

        let lat = contextType.requestParams.latitude;
        let long = contextType.requestParams.longitude;
        let offset = contextType.requestParams.offset;

        let url_string = "https://apiv2.omparashar.com/core/transit/snapshot";
        //var params = "?from_year=2020&from_month=1&from_day=1&to_year=2020&to_month=6&to_day=30&lat=29.47&long=77.69&offset=19800&p_nums=3&p_nums=4";
        let params =
          "?year=" +
          y +
          "&month=" +
          m +
          "&day=" +
          d +
          "&hour=" +
          h +
          "&minute=" +
          mi +
          "&second=" +
          s +
          "&lat=" +
          lat +
          "&long=" +
          long +
          "&offset=" +
          offset +
          "&city=" +
          "Hyderabad" +
          "&state=" +
          "Telangana" +
          "&country=" +
          "India" +
          "&reflong=" +
          82.5 +
          "&timezone=" +
          "Asia/Kolkata IST+0530" +
          "&token=" +
          localStorage.getItem("omp_token");
        const url = url_string + params;
        // console.log(url);
        const config = createSecureAxiosConfig(url);
        const result = await axios(config);
        setData(result.data.detailedPlanetPositions);
        //console.log(result)
      } else {
        setData([]);
      }
    })();
  }, [
    props.value,
    props.newTime,
    localStorage.getItem("UserDefaultLanguage"),
  ]);
  return (
    <>
      <div className="sideTableDiv pt-1">
        {/* <div className="sub-heading" style={{color:"#fff"}}> Date : <span style={{ "fontSize": "0.9em",marginTop:"40px"}} className="badge badge-pill badge-dark"> {date} </span> <br></br> At 12:00 AM @ {context.placeobserved} </div> */}
        <table
          className="table table-bordered side-table-natal"
          // style={{ height: "416px", fontSize: "13.3px" }}
        >
          <thead>
            <tr>
              <th scope="col">{t("Planet")}</th>
              <th scope="col">{t("Absolute Degree")}</th>
              <th scope="col">{t("Degree in Rashi")}</th>
              <th scope="col">{t("Nakshtra")}</th>
              <th scope="col">{t("Pada")}</th>
              <th scope="col">{t("Combust")}</th>
            </tr>
          </thead>
          <tbody>
            {typeof data !== "undefined"
              ? data.slice(1).map((item) => {
                  if (noncombust.indexOf(item.planet_name) >= 0)
                    item.combust = t("Never");
                  else
                    item.combust
                      ? (item.combust = t("Yes"))
                      : (item.combust = t("No"));
                  return (
                    <>
                      <tr key={item.planet_name + item.abs_degree}>
                        <td>
                          <img
                            className="PpImages-trans"
                            alt={item["planet_name"].slice(0, 3)}
                            src={extract_image_link(item["planet_name"])}
                          ></img>
                          {item.motion === "R" &&
                          item.planet_name !== "Rahu" &&
                          item.planet_name !== "Ketu"
                            ? `${item.planet_name} (R)`
                            : item.planet_name}
                        </td>
                        <td>{item.abs_degree}</td>
                        <td>
                          <img
                            className="rashi-images-trans"
                            alt={ImageMapper[item["r_d_m_s"].split(" ")[1]]}
                            src={`/assets/Zodiac/${
                              ImageMapper[item["r_d_m_s"].split(" ")[1]]
                            }.svg`}
                          ></img>

                          {item.r_d_m_s}
                        </td>
                        <td style={{ textAlign: "left" }}>
                          {" "}
                          <img
                            className="nakshatra-images-trans"
                            alt={item["nakshtra"]}
                            src={extract_image_link_nakshatra(item["nakshtra"])}
                          ></img>
                          {item.nakshtra}
                        </td>
                        <td>{item.nakshtra_pada}</td>
                        <td>{item.combust}</td>
                      </tr>
                    </>
                  );
                })
              : "."}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default NatalSidetable;
