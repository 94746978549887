import axios from "axios";
import { createSecureAxiosConfig,generateSecurityHeaders } from "../components/utils/utility";

const baseURL = "https://apiv2.omparashar.com/horoscope/analytics/yoga/";

//check user exist
export async function getSetandClasses() {
  try {
    let res = await axios({
      url: baseURL + "setsandclasses",
      method: "get",
       headers: generateSecurityHeaders(),
    });
    if (res.status === 200) {
      // test for status you want, etc
      //// console.log(res);
      //// console.log(res.data);

      return res.data;
    }
    // Don't forget to return something
    //return res;
  } catch (err) {
    console.error(err);
  }
}

//get books from vedic yoga
export async function getBooks() {
  try {
    let res = await axios({
      url: baseURL + "books",
      method: "get",
      headers: generateSecurityHeaders(),
    });
    if (res.status === 200) {
      // test for status you want, etc
      //// console.log(res);
      //// console.log(res.data);

      return res.data;
    }
    // Don't forget to return something
    //return res;
  } catch (err) {
    console.error(err);
  }
}

export async function insertVedicYoga(
  lang,
  fully_qualified_class_name,
  yoga_name,
  book_name,
  chapter,
  shloka_number,
  sanskrit_shloka,
  shloka_meaning,
  shloka_result,
  cancel_ddl,
  c_source,
  c_chapter,
  c_shloka_number,
  c_shloka,
  c_shloka_meaning,
  c_shloka_result,
  classification
) {
  try {
    let res = await axios({
      url: baseURL + "save?preferred_language=" + lang,
      method: "post",
      headers: generateSecurityHeaders(),
      data: {
        fully_qualified_class_name: fully_qualified_class_name,
        yoga_name: yoga_name,
        book_name: book_name.book_name,
        chapter: chapter,
        shloka_number: shloka_number,
        sanskrit_shloka: sanskrit_shloka,
        shloka_meaning: shloka_meaning,
        shloka_result: shloka_result,
        is_cancellation_present: cancel_ddl,
        cancellation:
          cancel_ddl === true
            ? {
                source: c_source.book_name,
                chapter: c_chapter,
                shloka_number: c_shloka_number,
                shloka: c_shloka,
                shloka_meaning: c_shloka_meaning,
                shloka_result: c_shloka_result,
              }
            : null,
        classification: classification,
      },
    });
    if (res.status === 200) {
      // test for status you want, etc
      // console.log(res);
      // console.log(res.data);

      return res;
    }
    // Don't forget to return something
    //return res;
  } catch (err) {
    console.error(err);
  }
}
//chekc yoga exist or not

//get books from vedic yoga
export async function getYoga(preferred_language, fullyqualifiedclassname) {
  try {
    const url = 
      "https://apiv2.omparashar.com/horoscope/analytics/yoga" +
      "?preferred_language=" +
      preferred_language +
      "&" +
      "fullyqualifiedclassname=" +
      fullyqualifiedclassname;
    
    const config = createSecureAxiosConfig(url, 'GET');
    
    let res = await axios(config);
    
    if (res.status === 200) {

      return res.data;
    }
  } catch (err) {
    console.error(err);
  }
}

//update vedic yoga
export async function updateVedicYoga(
  lang,
  fully_qualified_class_name,
  yoga_name,
  book_name,
  chapter,
  shloka_number,
  sanskrit_shloka,
  shloka_meaning,
  shloka_result,
  cancel_ddl,
  c_source,
  c_chapter,
  c_shloka_number,
  c_shloka,
  c_shloka_meaning,
  c_shloka_result,
  classification
) {
  try {
    let res = await axios({
      url: baseURL + "update?preferred_language=" + lang,
      method: "put",
      headers: generateSecurityHeaders(),
      data: {
        fully_qualified_class_name: fully_qualified_class_name,
        yoga_name: yoga_name,
        book_name: book_name.book_name,
        chapter: chapter,
        shloka_number: shloka_number,
        sanskrit_shloka: sanskrit_shloka,
        shloka_meaning: shloka_meaning,
        shloka_result: shloka_result,
        is_cancellation_present: cancel_ddl,
        cancellation:
          cancel_ddl === true
            ? {
                source: c_source.book_name,
                chapter: c_chapter,
                shloka_number: c_shloka_number,
                shloka: c_shloka,
                shloka_meaning: c_shloka_meaning,
                shloka_result: c_shloka_result,
              }
            : null,
        classification: classification,
      },
    });
    if (res.status === 200) {
      // test for status you want, etc
      //    console.log(res);
      //    console.log(res.data);

      return res;
    }
    // Don't forget to return something
    //return res;
  } catch (err) {
    console.error(err);
  }
}
export async function deleteYoga(preferred_language, fullyqualifiedclassname) {
  try {
    let res = await axios({
      url:
        baseURL +
        "delete?preferred_language=" +
        preferred_language +
        "&" +
        "fullyqualifiedclassname=" +
        fullyqualifiedclassname,
      method: "DELETE",
      headers: generateSecurityHeaders(),
    });
    if (res.status === 200) {
      // test for status you want, etc
      //console.log(res);
      return res.data;
    }
    // Don't forget to return something
    //return res;
  } catch (err) {
    console.error(err);
  }
}

export async function callVedicYogaReportAPI(lang) {
  let URL =
    "https://apiv2.omparashar.com/imageservice/reports/yogaDBreport?language=" +
    lang;
  //var params = "?from_year=2020&from_month=1&from_day=1&to_year=2020&to_month=6&to_day=30&lat=29.47&long=77.69&offset=19800&p_nums=3&p_nums=4";

  const config = createSecureAxiosConfig(URL,'GET');
  return axios({
    config
  })
    .then((response) => {
      if (response.status === 200) {
        // console.log(response);
        return response;
      } else {
        return "API Issue";
      }
    })
    .catch((error) => {
      // console.log(error);
    });
  // this.setState({open: false});
}
export async function callAnalizerReportAPI(
  name,
  gender,
  day,
  month,
  year,
  hour,
  min,
  sec,
  lat,
  long,
  offset,
  city,
  state,
  country,
  reflong,
  timezone,

  token
) {
  let URL = "https://apiv2.omparashar.com/imageservice/reports/analyzerreport";
  //var params = "?from_year=2020&from_month=1&from_day=1&to_year=2020&to_month=6&to_day=30&lat=29.47&long=77.69&offset=19800&p_nums=3&p_nums=4";
  let params =
    "?nativename=" +
    name +
    "&gender=" +
    gender +
    "&year=" +
    year +
    "&month=" +
    month +
    "&day=" +
    day +
    "&hour=" +
    hour +
    "&minute=" +
    min +
    "&second=" +
    sec +
    "&lat=" +
    lat +
    "&long=" +
    long +
    "&offset=" +
    offset +
    "&city=" +
    city +
    "&state=" +
    state +
    "&country=" +
    country +
    "&reflong=" +
    reflong +
    "&timezone=" +
    timezone +
    "&token=" +
    token;
  // console.log(URL + params);
  const config = createSecureAxiosConfig(URL + params,'GET');
  return axios(
   config
  )
    .then((response) => {
      if (response.status === 200) {
        // console.log(response);
        return response;
      } else {
        return "API Issue";
      }
    })
    .catch((error) => {
      // console.log(error);
    });
  // this.setState({open: false});
}
