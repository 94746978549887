import React, { useEffect, useState } from "react";
import "./CelebrityManagement.css";
import loadjs from "loadjs";
import Select from "react-select/async";
import { withRouter } from "react-router-dom";
import { useHistory } from "react-router";
import DashboardTopBar from "../DashBoardComponents/DashboardTopBar";
import { useTranslation } from "react-i18next";
import { insertEvent, updateEvents, deleteEvents } from "../../API/CelebrityManagement_API";
import CelebrityManagementSidebar from "../DashBoardComponents/DashboardSideBarTypes/CelebrityManagementSidebar";
import axios from "axios";
import EventAutoSuggest from "./Autosuggests/EventAutoSuggest";
import EventAutouggest from "./Autosuggests/EventAutoSuggest";
import { createSecureAxiosConfig } from "../utils/utility";

const Events = (props) => {
  let history = useHistory();
  const { t, i18n } = useTranslation();
  const [eventId, seteventId] = useState(null);
  const [eventName, seteventName] = useState('');
  const [updatedeventName, setupdatedeventName] = useState('');
  const [eventselectedvalue, seteventselectedvalue] = useState('');
  const [deletedeventselectedvalue, setDeletedeventselectedvalue] = useState('');
  const [oldeventId, setoldeventId] = useState(null);
  const [page_pass, setpage_pass] = useState(false);
  const [passinput, setpassInput] = useState(''); // '' is the initial state value
  const [eventData, setEventData] = useState();
  const [suggestions, setSuggestions] = useState([]);
  const [text, setText] = useState([]);
  const [suggestionsDelete, setSuggestionsDelete] = useState([]);

  useEffect(() => {
    const value = localStorage.getItem("devpass");
    if (value == "1") {
      setpage_pass(true);
    }
    else {
      setpage_pass(false);
    }
    const loadEvents = async () => {
      const url = 'https://apiv2.omparashar.com/celebrity/events/fetchevents?event_name';
      const config = createSecureAxiosConfig(url, 'GET');
      const response = await axios(config);
      setEventData(response.data.data);
    }
    loadEvents();
  }, [])

  let isValid = () => {
    if (eventName === "") { alert("Please enter event name"); return }
    return true;
  }
  let isvalidUpdate = () => {
    console.log(eventselectedvalue,'tt');
    if (eventselectedvalue === "select" || eventselectedvalue === "") { alert("Please select event"); return }
    if (updatedeventName === "") { alert("Please enter update event name"); return }
    return true;
  }
  let isvalidDelete = () => {
    if (deletedeventselectedvalue === "select" || deletedeventselectedvalue === "") { alert("Please select delete event"); return }
    return true;
  }
  const ResetAll = () => {
    seteventName("");
    // console.log('in reset all',updatedeventName);
    setText("")
    setupdatedeventName("");
    seteventselectedvalue("");
    setDeletedeventselectedvalue("");
    // console.log('in reset all',updatedeventName,eventselectedvalue,deletedeventselectedvalue);
  }

  const InsertEventName = () => {
    if (isValid()) {
      return insertEvent(eventName).then((res) => {
        if (!res) {
          alert("Error");
        }
        else {
          alert("Event saved successfully");
          ResetAll();
        }
      })
    }
    else {
      isValid();
    }
  }
  const UpdateEvent = async () => {
    if (isvalidUpdate()) {
      console.log(oldeventId,updatedeventName);
      const res = await updateEvents(oldeventId, updatedeventName);
      if (!res) {
        alert("Update event Error");
      }
      else {
        alert("Events Updated successfully");
        ResetAll();
      }
    }
    else {
      isvalidUpdate();
    }
  }

  const DeleteEvent = () => {
    if (isvalidDelete()) {
      return deleteEvents(oldeventId).then((res) => {
        if (!res) {
          alert("Error");
        }
        else {
          alert("Events deleted successfully");
          ResetAll();
        }
      })
    }
    else {
      isvalidDelete();
    }
  }
  const handleCelebrityManagementView = (selectedName) => {
    console.log("handleCelebrityManagementView....", selectedName);
    props.history.push(`/CelebrityManagement/${selectedName}`);
  };
  const pass_click = () => {
    if (passinput === "devteam@123") {
      localStorage.setItem("devpass", "1");
      history.push('/celebrityManagement');
    }
    else {
      setpassInput('');
      alert("Incorrect password, please try again ");
    }
  }
  //Autocomplete Update
  const onChangeHandler = (text) => {
    let matches = []
    if (text.length > 0) {
      matches = eventData.filter(data => {
        const regex = new RegExp(`${text}`, "gi");
        return data.event_name.match(regex);
      })
    }
    console.log("match...", matches);
    setSuggestions(matches);
    setText(text);
  }
  const onSuggestHandler = (text) => {
    setText(text);
    setSuggestions([]);
    eventData.map((item, i) => {
      if (text === item.event_name) {
        setoldeventId(item.id);
        seteventselectedvalue(item.event_name);
      }
    });
  }
  //Autocomplete Delete
  const onDeleteChangeHandler = (text) => {
    setDeletedeventselectedvalue(text);
    let match = [];
    if (text.length > 0) {
      match = eventData.filter(data => {
        const regex = new RegExp(`${text}`, "gi");
        return data.event_name.match(regex);
      })
    }
    setSuggestionsDelete(match);
  }

  const onDeleteSuggestHandler = (text) => {
    setDeletedeventselectedvalue(text);
    setSuggestionsDelete([]);
    eventData.map((item, i) => {
      if (text === item.event_name) {
        setoldeventId(item.id);
        setDeletedeventselectedvalue(item.event_name);
      }
    });
  }

  return (
    <>
      <DashboardTopBar />
      <CelebrityManagementSidebar handleView={handleCelebrityManagementView} history={props.history} />
      {/* <DashboardSideBar /> */}

      <div className="eventWrapper col-lg-12">
        <div className="card" style={{ width: "calc(100% - 240px)" }}>
          {page_pass ? (
            <div className="card-body">
              <div className="row mb-4" style={{ "marginTop": "-40px" }}>
                <div className="col-md-12 col-12">
                  <h1>{t("Event Management")} </h1>
                </div>
              </div>
              <div className="row">
                <div className="col-md-3 col-12">
                  <label>New event name:</label>
                </div>
                <div className="col-md-3 col-12">
                </div>
              </div>
              <div className="row mb-4">
                <div className="col-md-3 col-12">
                  {/* <label>{t("Event Name")}</label> */}
                  <input type="text" className="form-control" name="eventName" placeholder="Enter Event Name"
                    value={eventName} onChange={(e) => seteventName(e.target.value)}></input>
                </div>
                <div className="col-md-3 col-12">
                  <button type="button" className="btn btn-theme btn-lg" onClick={InsertEventName}>
                    {t("Save")}
                  </button>
                </div>
              </div>
              <div className="row">
                <div className="col-md-3 col-12">
                  <label>Event to be updated:</label>
                </div>
                  <div className="col-md-3 col-12">
                    <label>Updated Event name:</label>
                </div>
              </div>
              <div className="row mb-4">
                <div className="col-md-3 col-12">
                  {/*<div className="autocompleteWrapper">*/}
                  {/*  <input type="text" className="form-control col-md-12 autocomplete"*/}
                  {/*    onChange={e => onChangeHandler(e.target.value)}*/}
                  {/*    value={text}*/}
                  {/*    placeholder="Enter Event"*/}
                  {/*  />*/}
                  {/*  <ul className="suggestionWrapper" style={{ "z-index": "99" }}>*/}
                  {/*    {suggestions && suggestions.map((suggestion, i) =>*/}
                  {/*      <li className="suggestion col-md-12 justify-content-md-center"*/}
                  {/*        onClick={() => onSuggestHandler(suggestion.event_name)}>{suggestion.event_name}</li>*/}
                  {/*    )}*/}
                  {/*  </ul>*/}
                  {/*</div>*/}

                  <EventAutouggest event_name ={text} setLocation={(id,val)=>{setText(val);setoldeventId(id);seteventselectedvalue(val)}}
                                   index = "-1"/>
                </div>
                <div className="col-md-3 col-12">
                  <input type="text" className="form-control" name="updatedeventName" placeholder="Enter Event Name"
                    value={updatedeventName} onChange={(e) => setupdatedeventName(e.target.value)}></input>
                </div>
                <div className="col-md-3 col-12">
                  <label></label>
                  <button type="button" className="btn btn-update"
                    onClick={UpdateEvent}
                  >
                    {t("Update")}
                  </button>
                </div>
              </div>
              <div className="row">
                <div className="col-md-3 col-12">
                <label>Event to be deleted:</label>
                </div>
                <div className="col-md-3 col-12">
                </div>
              </div>
              <div className="row mb-4">
                {/*<div></div>*/}
                <div className="col-md-3 col-12">
                 {/*<div className="autocompleteWrapper">*/}
                 {/*   <input type="text" className="form-control col-md-12 autocomplete"*/}
                 {/*     onChange={e => onDeleteChangeHandler(e.target.value)}*/}
                 {/*     value={deletedeventselectedvalue}*/}
                 {/*     placeholder="Enter Deleted Event"*/}
                 {/*   // onBlur={() => setSuggestionsDelete([])}*/}
                 {/*   />*/}
                 {/*   <ul className="suggestionWrapper" style={{ "z-index": "99" }}>*/}
                 {/*     {suggestionsDelete && suggestionsDelete.map((suggestion, i) =>*/}
                 {/*       <li key={suggestion.id} className="suggestion col-md-12 justify-content-md-center"*/}
                 {/*         onClick={() => onDeleteSuggestHandler(suggestion.event_name)}>{suggestion.event_name}</li>*/}
                 {/*     )}*/}
                 {/*   </ul>*/}
                 {/* </div>*/}
                  <EventAutouggest event_name ={deletedeventselectedvalue} setLocation={(id,val)=>{console.log(id,val,'hh');setoldeventId(id);setDeletedeventselectedvalue(val)}}
                                   index = "-1"/>
                </div>
                <div className="col-md-3 col-12">
                  {/*<label style={{color:"white"}}>....</label>*/}

                  <button type="button" className="btn btn-delete"
                    onClick={DeleteEvent}
                  >
                    {t("Delete")}
                  </button>
                </div>
              </div>
            </div>
          ) : (
            <>
              <div className="card-body">
                <div className="row mb-4">
                  <div className="col-md-12 col-12">
                    <div className="col-md-8 col-12">
                      <label>Enter Password to access this section </label>
                      <div className="input-group mb-3">
                        <input
                          type="password"
                          value={passinput}
                          onChange={(event) => setpassInput(event.target.value)}
                          className="form-control"
                          placeholder="enter security password "
                        />
                        <div className="input-group-append">
                          <button
                            className="btn btn-outline-warning"
                            type="button"
                            onClick={pass_click}
                          >
                            Submit Password
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default withRouter(Events);

