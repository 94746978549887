import { Category } from "@material-ui/icons";
import axios from "axios";
import { createSecureAxiosConfig, generateSecurityHeaders } from "../components/utils/utility";

const baseURL = "https://apiv2.omparashar.com/celebrity/events/";
const baseTagsURL = "https://apiv2.omparashar.com/celebrity/tags/";
const baseCelebrityURL = "https://apiv2.omparashar.com/celebrity/";
/****************************Events API************************************/
export async function insertEvent(
  event_name
) {
  try {
    let res = await axios({
      url: baseURL + "save",
      method: "post",
      headers: generateSecurityHeaders(),
      data: {
        event_name: event_name
      },
    });
    if (res.status === 200) {
      console.log("eventres", res);
      console.log(res.data);

      return res;
    }
  } catch (err) {
    console.error(err);
  }
}
export async function getEvents(event_name) {
  try {
    let res = await axios({
      url: baseURL + "fetchevents?event_name=" + event_name,
      method: "get",
      headers: generateSecurityHeaders(),
    });
    if (res.status === 200) {
      return res.data;
    }
    //return res;
  } catch (err) {
    console.error(err);
  }
}
export async function updateEvents(id, event_name) {
  try {
    let res = await axios({
      url: baseURL + "update",
      method: "put",
      headers: generateSecurityHeaders(),
      data: {
        event_id: id,
        updated_event_name: event_name
      }
    });
    if (res.status === 200) {
      return res;
    }
    // Don't forget to return something
    //return res;
  } catch (err) {
    console.error(err);
  }
}
export async function deleteEvents(id) {
  try {
    let res = await axios({
      url:
        baseURL +
        "delete?event_name_oid=" +
        id,
      method: "DELETE",
      headers: generateSecurityHeaders(),
    });
    if (res.status === 200) {
      return res.data;
    }
  } catch (err) {
    console.error(err);
  }
}
/****************************Tags API************************************/
export async function insertTags(
  tag_name
) {
  try {
    let res = await axios({
      url: baseTagsURL + "save",
      method: "post",
      headers: generateSecurityHeaders(),
      data: {
        tag_name: tag_name
      },
    });
    if (res.status === 200) {
      console.log("eventres", res);
      console.log(res.data);

      return res;
    }
  } catch (err) {
    console.error(err);
  }
}
export async function getTags(tag_name) {
  try {
    let res = await axios({
      url: baseTagsURL + "fetchtag?tag_name=" + tag_name,
      method: "get",
      headers: generateSecurityHeaders(),
    });
    if (res.status === 200) {
      return res.data;
    }
    //return res;
  } catch (err) {
    console.error(err);
  }
}
export async function updateTags(id, tag_name) {
  try {
    let res = await axios({
      url: baseTagsURL + "update",
      method: "put",
      headers: generateSecurityHeaders(),
      data: {
        tag_id: id,
        updated_tag_name: tag_name
      }
    });
    if (res.status === 200) {
      return res;
    }
  } catch (err) {
    console.error(err);
  }
}
export async function deleteTags(id) {
  try {
    let res = await axios({
      url:
        baseTagsURL +
        "delete?tag_name_oid=" +
        id,
      method: "DELETE",
      headers: generateSecurityHeaders(),
    });
    if (res.status === 200) {
      //console.log(res);
      return res.data;
    }
  } catch (err) {
    console.error(err);
  }
}
/****************************Celebrity DB API************************************/
export async function searchbyName(username, category) {
  try {
    let res = await axios({
      url: baseCelebrityURL + "searchbyname?name=" + username + "&category=" + category,
      method: "get",
      headers: generateSecurityHeaders(),
    });
    if (res.status === 200) {
      return res.data;
    }
  } catch (err) {
    console.error(err);
  }
}
export async function searchbyAlphabet(alphabet, page_no, category) {
  try {
    let res = await axios({
      url: baseCelebrityURL + "dataforalphabet?alphabet=" + alphabet + "&page_no=" + page_no + "&category=" + category,
      method: "get",
      headers: generateSecurityHeaders(),
    });
    if (res.status === 200) {
      return res.data;
    }
  } catch (err) {
    console.error(err);
  }
}
export async function getAllData(oid, collection_name) {
  try {
    let res = await axios({
      url: baseCelebrityURL + "getalldata?oid=" + oid + "&collection_name=" + collection_name,
      method: "get",
      headers: generateSecurityHeaders(),
    });
    if (res.status === 200) {
      return res.data;
    }
  } catch (err) {
    console.error(err);
  }
}
export async function getGender() {
  try {
    let res = await axios({
      url: baseCelebrityURL + "genders",
      method: "get",
      headers: generateSecurityHeaders(),
    });
    if (res.status === 200) {
      return res.data;
    }
  } catch (err) {
    console.error(err);
  }
}
export async function getRatings() {
  try {
    let res = await axios({
      url: baseCelebrityURL + "ratings",
      method: "get",
      headers: generateSecurityHeaders(),
    });
    if (res.status === 200) {
      return res.data;
    }
  }
  catch (err) {
    console.error(err);
  }
}
export async function verifyData(first_name, last_name, gender, primary_birth_data, alternate_birth_data) {
  try {
    let res = await axios({
      url: baseCelebrityURL + "verifydata",
      method: "post",
      headers: generateSecurityHeaders(),
      data: {
        first_name: first_name,
        last_name: last_name,
        gender: gender,
        primary_birth_data: primary_birth_data,
        alternate_birth_data: alternate_birth_data
      }
    });
    if (res.status === 200) {
      console.log(res);
      return res;

    }

  } catch (err) {
    console.error(err);
  }
}
export async function createData(alldata, photofile, photoName) {
  // let alldata1 = {
  //   "workspace_name": "Admin",
  //   "record_url": "https://www.astro.com/astro-databank/Bachchan,_Amitabh",
  //   "rating": "DD",
  //   "first_name": "Lalita",
  //   "last_name": "Modi",
  //   "gender": "M",
  //   "primary_birth_data": {
  //     "year": 1942,
  //     "month": 10,
  //     "day": 19,
  //     "hour": 3,
  //     "minute": 30,
  //     "second": 0,
  //     "lat": 25.45,
  //     "long": 81.85,
  //     "offset": 23400,
  //     "city": "allahbad",
  //     "state": "uttar prades",
  //     "country": "india",
  //     "reflong": 97,
  //     "timezone": "india"
  //   },
  //   "alternate_birth_data": {
  //     "year": 1942,
  //     "month": 10,
  //     "day": 18,
  //     "hour": 3,
  //     "minute": 30,
  //     "second": 0,
  //     "lat": 25.45,
  //     "long": 81.85,
  //     "offset": 23400,
  //     "city": "allahbad",
  //     "state": "uttar prades",
  //     "country": "india",
  //     "reflong": 97,
  //     "timezone": "india"
  //   },
  //   "life_events": [
  //     {
  //       "event_name": "string",
  //       "event_date": "string",
  //       "event_description": "string"
  //     }
  //   ],
  //   "biography": "<p>Hi I'm Biographgy",
  //   "tag_references": "1942 births, Birthday 11 October, Birthplace Allahabad, INDIA, Sun 16 Libra, Moon 26 Libra, Asc 29 Leo, Vocation : Entertainment : Actor/ Actress, Vocation : Entertainment : TV host/ Personality, Vocation : Entertain/Music : Vocalist/ Pop, Rock, etc., Vocation : Entertain/Business : Entertain Producer, Vocation : Politics : Public office",
  //   "tags": [],
  //   "wikipedia_link": "string",
  //   "category": 1
  // }
  var bodyFormData = new FormData();
  bodyFormData.append('data', JSON.stringify(alldata));
  // bodyFormData.append('photo_name', "Romy_Haag_1_1_1948_5_45_0.jpg");
  if (photofile !== null) {
    bodyFormData.append("photo_file", photofile, photoName);
  }
  else if (photoName !== null){
    bodyFormData.append('photo_name', photoName);
  }
  console.log("response:", bodyFormData);
  try {
    const securityHeaders = generateSecurityHeaders();
  const headers = {
    ...securityHeaders,
    "Content-Type": "multipart/form-data"
  };
    let res = await axios({
      method: "post",
      url: baseCelebrityURL + "create",
      data: bodyFormData,
      headers: headers,
    })
    if (res.status === 200) {
      if (res.data.detail) {
        return [true,res.data.detail];
      }
    }
  }
  catch (err) {
    console.error(err);
    // console.log(err.response.data.detail,'dkwseoibdwskbckwdjs');
    return (err.response.data.detail);
  }
}

export async function updateData(alldata, photofile, photoName) {
  var bodyFormData = new FormData();
  bodyFormData.append('data', JSON.stringify(alldata));
  // bodyFormData.append('photo_name', photoName);
  // bodyFormData.append("photo_file", photofile, photoName);
  if (photofile !== null) {
    bodyFormData.append("photo_file", photofile, photoName);
  }
  else if (photoName !== null){
    bodyFormData.append('photo_name', photoName);
  }
  try {
    const securityHeaders = generateSecurityHeaders();
    const security_headers = {
      ...securityHeaders,
      "Content-Type": "multipart/form-data"
    };
    let res = await axios({
      method: "put",
      url: baseCelebrityURL + "update",
      data: bodyFormData,
      headers: security_headers,
    })
    if (res.status === 200) {
      if (res.data.detail) {
        return [true,res.data.detail];
      }
    }

  } catch (err) {
    console.error(err);
    return (err.response.data.detail);
  }
}
export async function deleteHoroscope(oid, category) {
  try {
    let res = await axios({
      url:
        baseCelebrityURL +
        "delete?oid=" + oid + "&category=" + category,
      method: "DELETE",
      headers: generateSecurityHeaders(),
    });
    if (res.status === 200) {
      // return res.data;
      return [true,res.data.detail];
    }
  } catch (err) {
    console.error(err);
    return (err.response.data.detail);
  }
}
export async function getAtlasDetails(cityId) {
  try {
    const url = "https://apiv2.omparashar.com/core/atlas/fulldetailscity?cityid=" + cityId;
    const config = createSecureAxiosConfig(url, 'GET');
    
    let res = await axios(config);
    
    // console.log(res);
    if (res.status === 200) {
      return res.data;
    }
    //return res;
  } catch (err) {
    console.error(err);
  }
}

export async function getPendingEntryCount(alphabet,category) {
  try {
    let res = await axios({
      url: baseCelebrityURL+"/pendingentrycounts?alphabet="+alphabet+"&category="+category,
      method: "get",
      headers: generateSecurityHeaders(),
    });
    // console.log(res);
    if (res.status === 200) {
      return res.data.pending_entries;
    }
    //return res;
  } catch (err) {
    console.error(err);
  }
}

/*****************************Celebrity Queries User End**************************************/

export async function basicSearch(data){
  try {
      let res = await axios({
        url: baseCelebrityURL + "basicquery",
        method: "post",
        headers: generateSecurityHeaders(),
        data: data
      });
      if (res.status === 200) {
        // console.log(res);
        return res;
      }
    } catch (err) {
      console.error(err);
    }
}


export async function queryBuilderSearch(data){
  try {
    let res = await axios({
      url: baseCelebrityURL+ "runquery",
      // url: "http://127.0.0.1:8004/"+ "runquery",
      method: "post",
      headers: generateSecurityHeaders(),
      responseType:"json",
      data: data
    });
    if (res.status === 200) {
      // console.log(res);
      return res;
    }
  } catch (err) {
    console.error(err);
  }
}

export async function saveTemplate(data){
  try {
    let res = await axios({
      // url: "http://127.0.0.1:8004/"+ "savetemplate",
      url: baseCelebrityURL+ "savetemplate",
      method: "post",
      headers: generateSecurityHeaders(),
      data: data
    });
    if (res.status === 200) {
      console.log(res);
      return res.data;
    }
  } catch (err) {
    if(err.response.status===409){
      return err.response.data
    }
    console.error(err,err.response.data,err.response.status);

    // return res
  }
}

export async function getTemplateNames() {
  try {
    let res = await axios({
      url: baseCelebrityURL+ "getalltemplatenames",
      method: "get",
      headers: generateSecurityHeaders(),
    });
    // console.log(res);
    if (res.status === 200) {
      return res.data;
    }
    //return res;
  } catch (err) {
    console.error(err);
  }
}

export async function getAllTemplates() {
  try {
    let res = await axios({
      // url: "http://127.0.0.1:8004/"+ "getalltemplates",
      url: baseCelebrityURL+ "getalltemplates",
      method: "get",
      headers: generateSecurityHeaders(),
    });
    // console.log(res);
    if (res.status === 200) {
      return res.data;
    }
    //return res;
  } catch (err) {
    console.error(err);
  }
}

export async function searchByTemplateName(templateName,pageNumber){
  try {
    let res = await axios({
      url: baseCelebrityURL+ "querytemplate?templatename="+templateName+"&page_number="+pageNumber,
      // url: "http://127.0.0.1:8004/"+ "querytemplate?templatename="+templateName+"&page_number="+pageNumber,
      method: "get",
      headers: generateSecurityHeaders(),
      responseType:"json",
    });
    if (res.status === 200) {
      // console.log(res);
      return res;
    }
  } catch (err) {
    console.error(err);
  }
}

