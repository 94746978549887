import React, { useEffect, useState } from "react";
import "./VedicYoga.css";
import loadjs from "loadjs";
import Select from "react-select/async";
import { Form, Row, Col, Modal, Button } from "react-bootstrap";
import { withRouter } from "react-router-dom";
import { GlobalContext } from "../../mycontext";
import DashboardTopBar from "../DashBoardComponents/DashboardTopBar";
import DashboardSideBar from "../DashBoardComponents/DashboardSideBar";
import { FaAndroid, FaArrowLeft, FaCaretLeft, FaCaretRight } from "react-icons/fa";
import { getSetandClasses, getBooks, insertVedicYoga, getYoga, updateVedicYoga, deleteYoga, callVedicYogaReportAPI } from "../../API/VedicYoga_API";
import { useTranslation } from "react-i18next";
import { downloadReportPDF } from "../../API/UserAPIs";

const VedicYoga = () => {

  let array_api = [];
  const { t, i18n } = useTranslation();

  const [cancel_ddl, setcancel_ddl] = useState(false);
  const [page_pass, setpage_pass] = useState(false);
  const [passinput, setpassInput] = useState(''); // '' is the initial state value
  const [active, setActive] = useState(false);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  let [loading, setLoading] = useState(false);
  // ddl for language 
  const [inputlangvalue, setinputlangvalue] = useState('');
  const [langselected, setlangselected] = useState(null);

  // ddl for book values
  const [bookinputvalue, setbookinputvalue] = useState('');
  const [bookselectedvalue, setbookselectedvalue] = useState(null);

  //ddl for cancel area book value
  const [bookinputvalue2, setbookinputvalue2] = useState('');
  const [bookselectedvalue2, setbookselectedvalue2] = useState(null);

  const [inputsetsvalue, setinputsetsvalue] = useState('');
  const [selectedsetsvalue, setselectedsetsvalue] = useState(null);

  const [classvalue, setclassvalue] = useState('');
  const [classSetLength, setclassSetLength] = useState(0);
  const [classArrayLength, setclassArrayLength] = useState(0);
  const [classarrayindexlive, setclassarrayindexlive] = useState(0);

  //states for API INsert 
  const [fullclassname, setfullclassname] = useState('');
  const [yoganame, setyoganame] = useState('');
  const [bookname1, setbookname1] = useState('');
  const [chapter, setchapter] = useState('');
  const [shloka_number, setshloka_number] = useState('');
  const [sanskrit_shloka, setsanskrit_shloka] = useState('');
  const [shloka_meaning, setshloka_meaning] = useState('');
  const [shloka_result, setshloka_result] = useState('');
  const [is_cancellation_present, setis_cancellation_present] = useState('');
  const [cancelsource, setcancelsource] = useState('');
  const [cancelchapter, setcancelchapter] = useState('');
  const [cancelshloka_number, setcancelshloka_number] = useState('');
  const [cancelshloka, setcancelshloka] = useState('');
  const [cancelshloka_meaning, setcancelshloka_meaning] = useState('');
  const [cancelshloka_result, setcancelshloka_result] = useState('');
  const [classification, setclassification] = useState('');
  const [isFormValid, setIsFormValid] = useState(false);
  const [filter1, setFilter1] = useState([]);

  const handleInputchange = (value) => {
    setbookinputvalue(value);
  }

  const handleBookChange = (value) => {
    setbookselectedvalue(value);
  }

  const handleInputchange2 = (value) => {
    setbookinputvalue2(value);
    //setcancelsource(value)
  }

  const handleBookChange2 = (value) => {
    setbookselectedvalue2(value);
  }
  useEffect(() => {
    // loadjs("assets/js/app.js", () => { });
    loadjs("assets/js/vendor.min.js", () => {});
    let language = localStorage.getItem("UserDefaultLanguage");
    if (language !== null) {
      if (language === "Hindi") {
        i18n.changeLanguage("hi");
      } else {
        i18n.changeLanguage("en");
      }
    }
  }, []);

  const handleSetsChange = (value) => {
    var newVal = value.substring(1);
    if (inputlangvalue === "select" || inputlangvalue === "") { alert("Please select language"); return }
    ResetAll();
    setinputsetsvalue(value)
    GetSetandClass(newVal);
  }
   useEffect(() => {
     getSetandClasses().then((res) => {
      const arr = [];
      for (let i in res) {
        let key = i;
        arr.push(key);
      }
      setFilter1(arr.sort(function (a, b) {
        var charPart = [a.substring(0, 1), b.substring(0, 1)],
          numPart = [a.substring(1) * 1, b.substring(1) * 1];

        if (charPart[0] < charPart[1]) return -1;
        else if (charPart[0] > charPart[1]) return 1;
        else {
          if (numPart[0] < numPart[1]) return -1;
          else if (numPart[0] > numPart[1]) return 1;
        }
      })
      )
    });
  }, [])
  const handleLangchnage = (value) => {
    setinputlangvalue(value);

    if (classvalue[classarrayindexlive] != undefined && classvalue[classarrayindexlive] != "" && classvalue[classarrayindexlive] != null) {
      CheckYogaExist(value, classvalue[classarrayindexlive]);
    }

  }

  const handleclassification_chnage = (value) => {
    setclassification(value);
  }

  const GetSetandClass = (val) => {
    if (val != 0) {
      return getSetandClasses().then((res) => {
        array_api = res[`S${val}`];
        CheckYogaExist(inputlangvalue, array_api[0])
        setclassvalue(array_api);
        setclassSetLength(array_api.length);
        setclassarrayindexlive(0);

      })
    }
    else {
      alert("Please select set");
    }
  }

  const GetVedicBooks = () => {
    return getBooks().then((res) => {
      return res.books;
    });
  }

  // const setFilter = [
  //   { value: "0", label: "Select Set" },
  //   { value: "1", label: "Set - S1" },
  //   { value: "2", label: "Set - S2" },
  //   { value: "3", label: "Set - S3" },
  //   { value: "4", label: "Set - S4" },
  //   { value: "5", label: "Set - S5" },
  //   { value: "6", label: "Set - S6" },
  //   { value: "7", label: "Set - S7" },
  //   { value: "8", label: "Set - S8" },
  //   { value: "9", label: "Set - S9" },
  //   { value: "10", label: "Set - S10" },
  //   { value: "11", label: "Set - S11" },
  //   { value: "12", label: "Set - S12" },

  // ];

  const pass_click = () => {
    if (passinput === "devteam@123") {
      setpage_pass(true);
    }
    else {
      setpassInput('');
      alert("Incorrect password, please try again ");
    }
  }

  const classificationFilter = [{ value: "Auspicious", label: "Auspicious" }];

  const handle_cancel_ddl = (e) => {
    if (e.target.value === "true") {
      setcancel_ddl(true);
    }
    else if (e.target.value === "false") {
      setcancel_ddl(false);
    }
  }

  const ResetAll = () => {
    setfullclassname("");
    setyoganame("");
    setbookname1("");
    setbookselectedvalue("");
    setbookinputvalue("");
    setchapter("");
    setshloka_number("");
    setsanskrit_shloka("");
    setshloka_meaning("");
    setshloka_result("");
    setis_cancellation_present("");
    setbookselectedvalue2("");
    setcancelchapter("");
    setcancelshloka_number("");
    setcancelshloka("");
    setcancelshloka_meaning("");
    setcancelshloka_result("");
    setclassification("");
  }
  const CheckYogaExist = (lang, classdata) => {
    ResetAll();
    if (inputlangvalue === "select" && inputlangvalue === "" && inputlangvalue === undefined) {
      alert("Please select language");
    }
    else {
      return getYoga(lang, classdata).then((res) => {
        console.log("CheckYogaExist", res);
        if (res.yoga_found === true && res.yoga_found !== undefined) {
          setActive(true);
          let data = res.result;
          setfullclassname(data.fully_qualified_class_name);
          setyoganame(data.yoga_name);
          setbookselectedvalue(data.book_reference);
          setclassification(data.classification)
          setchapter(data.chapter);
          setshloka_number(data.shloka_number);
          setsanskrit_shloka(data.sanskrit_shloka);
          setshloka_meaning(data.shloka_meaning);
          setshloka_result(data.shloka_result);
          setis_cancellation_present(data.cancellation);
          if (data.cancellation != null) {
            setcancel_ddl(true);
            setbookselectedvalue2(data.cancellation.source);
            setcancelchapter(data.cancellation.chapter);
            setcancelshloka_number(data.cancellation.shloka_number);
            setcancelshloka(data.cancellation.shloka);
            setcancelshloka_meaning(data.cancellation.shloka_meaning);
            setcancelshloka_result(data.cancellation.shloka_result);
            setclassification(data.classification);
          }
          else {
            setcancel_ddl(false);
          }
        }
        else {
          setcancel_ddl(false);
          setActive(false);
        }
        return res;
      })
    }


  }
  const IncreaseClassby1 = () => {
    //classArrayLength

    if ((classarrayindexlive + 1 < classSetLength) === true) {
      ResetAll();
      if (classarrayindexlive + 1 < classSetLength) {
        setclassarrayindexlive(classarrayindexlive + 1);
      }
      CheckYogaExist(inputlangvalue, classvalue[classarrayindexlive + 1]);
    }
  }
  const DecreaseClassby1 = () => {
    //classArrayLength
    if (classarrayindexlive != 0) {
      ResetAll();
      if ((classarrayindexlive + 1 > 1) && (classarrayindexlive + 1) <= classSetLength) {
        setclassarrayindexlive(classarrayindexlive - 1);
      }
      CheckYogaExist(inputlangvalue, classvalue[classarrayindexlive - 1]);
    }
  }
  let isValid = () => {
    if (inputlangvalue === "select" || inputlangvalue === "") { alert("Please select language"); return }
    setfullclassname(classvalue[classarrayindexlive]);
    if (classvalue[classarrayindexlive] === "") { alert("Please select class name"); return }
    setbookname1(bookselectedvalue);
    if (bookselectedvalue === "") { alert("Please select book name"); return }
    if (classification === "" || classification === "0") { alert("Please select Classification"); return }

    if (cancel_ddl == true) {
      if (bookselectedvalue2 === "") { alert("Please select cancellation book name"); return }
    }
    return true;
  }
  const InsertVedicYoga = () => {

    if (isValid()) {
      return insertVedicYoga(
        inputlangvalue,
        classvalue[classarrayindexlive],
        yoganame,
        bookselectedvalue,
        chapter,
        shloka_number,
        sanskrit_shloka,
        shloka_meaning,
        shloka_result,
        cancel_ddl,
        bookselectedvalue2,
        cancelchapter,
        cancelshloka_number,
        cancelshloka,
        cancelshloka_meaning,
        cancelshloka_result,
        classification
      ).then((res) => {

        if (!res) {
          alert("Error");
        }
        else {
          alert("Vedic Yoga saved successfully");
          setActive(true);
          // CheckYogaExist(inputlangvalue, array_api[0])
          CheckYogaExist(inputlangvalue, classvalue[classarrayindexlive]);

        }
      })
    }
    else {
      isValid();
    }
  }

  const UpdateVedicYoga = () => {
    isValid();

    if (isValid()) {
      return updateVedicYoga(
        inputlangvalue,
        fullclassname,
        yoganame,
        bookselectedvalue,
        chapter,
        shloka_number,
        sanskrit_shloka,
        shloka_meaning,
        shloka_result,
        cancel_ddl,
        bookselectedvalue2,
        cancelchapter,
        cancelshloka_number,
        cancelshloka,
        cancelshloka_meaning,
        cancelshloka_result,
        classification
      ).then((res) => {
        if (!res) {
          alert("Error");
        }
        else {
          alert("Vedic Yoga Updated successfully");
        }

      })
        .catch((err) => {
          alert("Error");
        })
    }
    else {
      isValid();
    }
  }
  const DeleteVedicYoga = () => {
    handleClose();
    return deleteYoga(inputlangvalue, classvalue[classarrayindexlive]).then((res) => {

      if (!res) {
        alert("Error");
      }
      {
        alert("Vedic Yoga deleted successfully");
        setActive(false);
        ResetAll();
      }
    })
  }
  //Reports

  let callVedicYogaReportAPIHandler = () => {

    if (inputlangvalue === "select" || inputlangvalue === "") { alert("Please select language"); return }
    else {
      try {
        callVedicYogaReportAPI(
          inputlangvalue
        )
          .then((res1) => {
            setLoading(true);
            if (typeof res1 !== "undefined") {
              if (res1 === "API Issue") {
                console.log("Issue with Reports API");
              } else {
                downloadReportPDF(res1.data.url).then((res2) => {
                  if (typeof res2 !== "undefined") {
                    console.log(res2);
                    const fileName = "Omparashar-VedicYogaReport-" + ".pdf";
                    const blobobject = new Blob([res2.data], {
                      type: "application/pdf",
                    });
                    console.log(blobobject);
                    const link = document.createElement("a");
                    link.href = window.URL.createObjectURL(blobobject);
                    link.setAttribute("download", fileName);
                    link.click();
                    setLoading(false);
                    // link.download = "FileName" + new Date() + ".pdf";
                    // link.click();
                  } else {
                    console.log(
                      "issue with Download Report EndPoint in User APIs"
                    );
                    setLoading(false);
                  }
                });
              }
            } else {
              console.log(
                "issue with Download Report EndPoint in Image Service APIs"
              );
              setLoading(false);
            }
          });
      } catch (err) {
        console.log("Issue with Reports API");
        console.log(err);
      }
    }
  };
  return (
    <>
      <DashboardTopBar />
      <DashboardSideBar />

      <div className="YogaWrapper col-lg-12">
        <div className="card">
          {page_pass ? (
            <>
              <div className="card-body">
                <div className="row mb-4" style={{ "marginTop": "-40px" }}>
                  <div className="col-md-1 col-12"></div>
                  <div className="col-md-5 col-12">
                    <h1>{t("Vedic Yoga Management")} </h1>
                  </div>
                  <div className="col-md-2 col-12">
                    {/* <label>Select Language</label> */}
                    <div className="select-container pt-4">
                      <select value={inputlangvalue} onChange={(e) => handleLangchnage(e.target.value)}>
                        <option value="0">{("Select Language")}</option>
                        <option value="hi">{("Hindi")}</option>
                        <option value="en">{("English")}</option>
                      </select>
                    </div>
                  </div>
                </div>

                <div className="row mb-2">
                  <div className="col-md-3 col-12">
                    <label>{t("Load Yoga from set")}</label>
                    <div
                      className="select-container"
                      style={{ width: "150px" }}
                    >
                      {/* <select value={inputsetsvalue} onChange={(e) => handleSetsChange(e.target.value)}>
                        {setFilter.map((option) => (
                          <option value={option.value}>
                            {option.label}
                          </option>
                        ))}
                      </select> */}
                      <select value={inputsetsvalue} onChange={(e) => handleSetsChange(e.target.value)} placeholder="Select Set">
                        <option value=""> Select Set</option>
                        {filter1.map((option) => (
                          <option value={option}>
                            {option}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div className="col-md-7 col-12">
                    <label>{t("Class Name")} {" "} {t("Set-S")}{inputsetsvalue}, {t("Class")} {classarrayindexlive + 1} of {classSetLength}</label>
                    <div class="input-group-append">
                      <a onClick={DecreaseClassby1} href="#" style={{ "paddingTop": "5px" }} disabled={classarrayindexlive != 0 ? true : false}>
                        <FaCaretLeft class="fa-2x" className={classarrayindexlive === 0 ? "disabled" : "enabled"} />
                      </a>
                      <input class="form-control" value={classvalue[classarrayindexlive]} onChange={(e) => setclassvalue(e.target.value)} disabled />
                      <a onClick={IncreaseClassby1} href="#" style={{ "paddingTop": "5px" }}>
                        <FaCaretRight class="fa-2x" className={(classarrayindexlive + 1 < classSetLength) === false ? "disabled" : "enabled"} />
                      </a>
                    </div>
                  </div>
                  <div className="col-md-3 col-12"></div>
                </div>

                <div
                  class="scroll-row"
                // style={{ height: "850px", "overflow-y": "scroll" }}
                >
                  <div className="row mb-2">
                    <div className="col-md-3 col-12">
                      <label>{t("Yoga Name")}</label>
                      <input className="form-control" name="YogaName" placeholder="AshrayaRajjuYoga"
                        value={yoganame} onChange={(e) => setyoganame(e.target.value)}></input>
                    </div>
                    <div className="col-md-3 col-12">
                      <label>{t("Book Name")}</label>

                      <div className="select-container">

                        <Select
                          cacheOptions
                          defaultOptions
                          value={bookselectedvalue}
                          getOptionLabel={(e) => e.book_name}
                          getOptionValue={(e) => e.book_name}
                          loadOptions={GetVedicBooks}
                          onInputChange={handleInputchange}
                          onChange={handleBookChange}
                        />
                      </div>
                    </div>
                    <div className="col-md-3 col-12">
                      <label>{t("Classification")}</label>
                      <div className="select-container">
                        <select value={classification} onChange={(e) => handleclassification_chnage(e.target.value)}>
                          <option value="0">{t("Select Classification")}</option>
                          <option value="Auspicious">{t("Auspicious")}</option>
                          <option value="Inauspicious">{t("Inauspicious")}</option>
                          <option value="Ordinary">{t("Ordinary")}</option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="row mb-2">
                    <div className="col-md-3 col-12">
                      <label>{t("Chapter Name")}</label>
                      <input
                        className="form-control" name="ChapterName" placeholder="11"
                        value={chapter} onChange={(e) => setchapter(e.target.value)}></input>
                    </div>
                    <div className="col-md-3 col-12">
                      <label>{t("Shloka Number")}</label>
                      <input value={shloka_number} onChange={(e) => setshloka_number(e.target.value)}
                        className="form-control"
                        name="ShlokarName"
                        placeholder="19"
                      ></input>
                    </div>
                    <div className="col-md-3 col-12"></div>
                  </div>
                  <div className="row mb-2">
                    <div className="col-md-5 col-12">
                      <label>{t("Shloka")}</label>
                      <textarea value={sanskrit_shloka} onChange={(e) => setsanskrit_shloka(e.target.value)}
                        className="form-control"
                        name="shloka"
                        placeholder="आǑद देव नमस्तुभ्यंप्रसीद मम
                भास्कर:।
                Ǒदवाकर नमस्तुभ्यंप्रभाकर नमोऽस्तुते
                ।1।"
                      />
                    </div>
                    <div className="col-md-5 col-12">
                      <label>{t("Shloka Meaning")}</label>
                      <textarea value={shloka_meaning} onChange={(e) => setshloka_meaning(e.target.value)}
                        className="form-control"
                        name="shloka"
                        placeholder="shloka meaning.."
                      />
                    </div>
                  </div>
                  <div className="row mb-2">
                    <div className="col-md-5 col-12">
                      <label>{t("Result")}</label>
                      <textarea value={shloka_result} onChange={(e) => setshloka_result(e.target.value)}
                        className="form-control"
                        name="result"
                        placeholder="Result"
                      />
                    </div>
                    <div className="col-md-3 col-12">
                      <label>{t("Cancellation Exists")}</label>
                      <div className="select-container">
                        <select value={cancel_ddl} onChange={handle_cancel_ddl}>
                          <option value="true">{t("True")}</option>
                          <option value="false">{t("False")}</option>
                        </select>
                      </div>

                    </div>
                  </div>

                  {cancel_ddl ? (
                    <>
                      <div id="cancellation_div">
                        <hr />

                        <h3 style={{ color: "red" }}>{t("Cancellation")}</h3>

                        <div className="row mb-2">
                          <div className="col-md-3 col-12">
                            <label>{t("Book Name")}</label>
                            <div className="select-container">
                              <Select
                                cacheOptions
                                defaultOptions
                                value={bookselectedvalue2}
                                getOptionLabel={(e) => e.book_name}
                                getOptionValue={(e) => e.book_name}
                                loadOptions={GetVedicBooks}
                                onInputChange={handleInputchange2}
                                onChange={handleBookChange2}
                              />
                            </div>
                          </div>
                          <div className="col-md-3 col-12">
                            <label>{t("Chapter Name")}</label>
                            <input value={cancelchapter} onChange={(e) => setcancelchapter(e.target.value)}
                              className="form-control"
                              name="ChapterName"
                              placeholder="11"
                            ></input>
                          </div>
                          <div className="col-md-3 col-12">
                            <label>{t("Shloka Number")}</label>
                            <input value={cancelshloka_number} onChange={(e) => setcancelshloka_number(e.target.value)}
                              className="form-control"
                              name="ShlokarName"
                              placeholder="19"
                            ></input>
                          </div>
                        </div>
                        <div className="row mb-2">
                          <div className="col-md-5 col-12">
                            <label>{t("Shloka")}</label>
                            <textarea value={cancelshloka} onChange={(e) => setcancelshloka(e.target.value)}
                              className="form-control"
                              name="shloka"
                              placeholder="आǑद देव नमस्तुभ्यंप्रसीद मम
                            भास्कर:।
                            Ǒदवाकर नमस्तुभ्यंप्रभाकर नमोऽस्तुते
                            ।1।"
                            />
                          </div>
                          <div className="col-md-5 col-12">
                            <label>{t("Shloka Meaning")}</label>
                            <textarea value={cancelshloka_meaning} onChange={(e) => setcancelshloka_meaning(e.target.value)}
                              className="form-control"
                              name="shloka"
                              placeholder="shloka meaning.."
                            />
                          </div>
                        </div>
                        <div className="row mb-2">
                          <div className="col-md-5 col-12">
                            <label>{t("Result")}</label>
                            <textarea value={cancelshloka_result} onChange={(e) => setcancelshloka_result(e.target.value)}
                              className="form-control"
                              name="result"
                              placeholder="Result"
                            />
                          </div>
                        </div>
                      </div>
                    </>
                  ) : (
                    ""
                  )}
                  {/* START Delete Popup  */}
                  <div>
                    <Modal show={show} className="deleteYoga"
                      onHide={handleClose}
                      backdrop="static"
                      keyboard={false}
                    >
                      <Modal.Header closeButton style={{ "borderBottom": "1px solid #dee2e6" }}>
                        <Modal.Title>{t("Vedic Yoga")} </Modal.Title>
                      </Modal.Header>
                      <Modal.Body>
                        {t("Are you sure you want to delete vedic Yoga?")}
                      </Modal.Body>
                      <Modal.Footer style={{ "borderTop": "1px solid #dee2e6" }}>
                        <Button variant="secondary" onClick={handleClose}>
                          {t("No")}
                        </Button>
                        <Button variant="primary" onClick={DeleteVedicYoga}>{t("Yes")}</Button>
                      </Modal.Footer>
                    </Modal>
                  </div>
                  {/* END Delete Popup  */}

                  <div className="row">
                    <div class="col-md-12 col-12">
                      {!active ? (
                        <button type="button" class="btn btn-success btn-lg" onClick={InsertVedicYoga}>
                          {t("Save Details")}
                        </button>) : (<button type="button" class="btn btn-success btn-lg" onClick={UpdateVedicYoga}>
                          {t("Update Details")}
                        </button>)}
                      &nbsp;&nbsp;
                      {!active ? (
                        <button type="button" class="btn btn-secondary btn-lg" onClick={ResetAll}>
                          {("Reset")}
                        </button>) : (<button type="button" class="btn btn-danger btn-lg" onClick={handleShow}
                        // onClick={DeleteVedicYoga}
                        >
                          {t("Delete Details")}
                        </button>)}
                      {/* {inputlangvalue ? (<button
                        data-toggle="collapse"
                        type="button"
                        className="btn btn-success  btn-lg ml-2"
                        onClick={() => callVedicYogaReportAPIHandler()}
                        disabled={loading}
                      >
                        {loading ? (
                          <span>
                            {t("Downloading Report")}{" "}
                            <i className="mdi mdi-spin mdi-loading mr-1 font-14"></i>
                          </span>
                        ) : (
                          <span>{t("Download Vedic Yoga PDF")}</span>
                        )}
                      </button>) : (<button
                        data-toggle="collapse"
                        type="button"
                        className="btn btn-warning  btn-lg ml-2"
                        onClick={() => callVedicYogaReportAPIHandler()}
                      >
                        <span>{t("Download Vedic Yoga PDF")}</span>
                      </button>)} */}
                    </div>
                  </div>
                </div>
              </div>
            </>
          ) : (
            <>
              <div className="card-body">
                <div className="row mb-4">
                  <div className="col-md-12 col-12">
                    <div className="col-md-8 col-12">
                      <label>Enter Password to access this section </label>
                      <div class="input-group mb-3">
                        <input
                          type="password"
                          value={passinput}
                          onChange={(event) => setpassInput(event.target.value)}
                          className="form-control"
                          placeholder="enter security password "
                        />
                        <div class="input-group-append">
                          <button
                            className="btn btn-outline-warning"
                            type="button"
                            onClick={pass_click}
                          >
                            Submit Password
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default withRouter(VedicYoga);




