import React, { useEffect, useState } from "react";
import "./CelebrityManagement.css";
import { useHistory } from "react-router";
import { GlobalContext } from "../../mycontext";
import DashboardTopBar from "../DashBoardComponents/DashboardTopBar";
import { useTranslation } from "react-i18next";
import { insertTags, getTags, updateTags, deleteTags } from "../../API/CelebrityManagement_API";
import CelebrityManagementSidebar from "../DashBoardComponents/DashboardSideBarTypes/CelebrityManagementSidebar";
import axios from "axios";
import TagAutosuggest from "./Autosuggests/TagAutoSuggest";
import { createSecureAxiosConfig } from "../utils/utility";

const Tags = (props) => {
  const context = React.useContext(GlobalContext);
  let history = useHistory();

  const { t, i18n } = useTranslation();
  const [tagId, settagId] = useState(null);
  const [tagName, settagName] = useState('');
  const [updatedtagName, setupdatedtagName] = useState('');
  const [deletedtagselectedvalue, setdeletedtagselectedvalue] = useState([]);
  const [oldtagId, setoldtagId] = useState(null);
  const [tagselectedvalue, settagselectedvalue] = useState('');
  const [page_pass, setpage_pass] = useState(false);
  const [passinput, setpassInput] = useState(''); // '' is the initial state value
  const [alltagdata, setAlltagdata] = useState();
  const [suggestions, setSuggestions] = useState([]);
  const [text, setText] = useState([]);
  const [suggestionsDelete, setSuggestionsDelete] = useState([]);

  useEffect(() => {
    const value = localStorage.getItem("devpass");
    if (value == "1") {
      setpage_pass(true);
    }
    else {
      setpage_pass(false);
    }

    const loadTags = async () => {
      const url = 'https://apiv2.omparashar.com/celebrity/tags/fetchtag?tag_name';
      const config = createSecureAxiosConfig(url, 'GET');
      const response = await axios(config);
      setAlltagdata(response.data.data);
    }
    loadTags();
  }, []);

  let isValid = () => {
    if (tagName === "") { alert("Please enter tag name"); return }
    return true;
  }
  let isvalidUpdate = () => {
    if (tagselectedvalue === "select" || tagselectedvalue === "") { alert("Please select tag"); return }
    if (updatedtagName === "") { alert("Please enter update tag name"); return }
    return true;
  }
  let isvalidDelete = () => {
    if (deletedtagselectedvalue === "select" || deletedtagselectedvalue === "") { alert("Please select delete tag name"); return }
    return true;
  }
  const ResetAll = () => {
    settagName("");
    setText("");
    setupdatedtagName("");
    settagselectedvalue("");
    setdeletedtagselectedvalue("");
  }
  const InsertTagName = () => {
    if (isValid()) {
      return insertTags(tagName).then((res) => {
        if (!res) {
          alert("Error");
        }
        else {
          alert("Tag saved successfully");
          ResetAll();
        }
        console.log("res", res);
      })
    }
    else {
      isValid();
    }
  }
  const UpdateTags = () => {
    console.log("updateTag", oldtagId, updatedtagName);
    if (isvalidUpdate()) {
      const res = updateTags(oldtagId, updatedtagName);
      if (!res) {
        alert("Update Tags Error");
      }
      else {
        alert("Tags Updated successfully");
        ResetAll();
      }
    }
    else {
      isvalidUpdate();
    }
  }
  // const handleDeleteInputchange = (e) => {
  //   console.log(e,"handleDeleteInputchange", e.id)
  //   // setoldtagId(e.id);
  //   setdeletedtaginputvalue(e);
  // }

  const DeleteTag = () => {
    console.log(oldtagId, "dele");
    if (isvalidDelete()) {
      return deleteTags(oldtagId).then((res) => {
        if (!res) {
          alert("Error");
        }
        else {
          alert("Tag deleted successfully");
          ResetAll();
        }
      })
    }
    else {
      isvalidDelete();
    }
  }
  const handleCelebrityManagementView = (selectedName) => {
    console.log("handleCelebrityManagementView....", selectedName);
    props.history.push(`/CelebrityManagement/${selectedName}`);
  };
  const pass_click = () => {
    if (passinput === "devteam@123") {
      localStorage.setItem("devpass", "1");
      history.push('/celebrityManagement');
    }
    else {
      setpassInput('');
      alert("Incorrect password, please try again ");
    }
  }
  //Autocomplete Update
  const onChangeHandler = (text) => {
    let matches = []
    if (text.length > 0) {
      matches = alltagdata.filter(data => {
        const regex = new RegExp(`${text}`, "gi");
        return data.tag_name.match(regex);
      })
    }
    console.log("match...", matches);
    setSuggestions(matches);
    setText(text);
  }
  const onSuggestHandler = (text) => {
    setText(text);
    setSuggestions([]);
    alltagdata.map((item, i) => {
      if (text === item.tag_name) {
        setoldtagId(item.id);
        settagselectedvalue(item.tag_name);
      }
    });
  }
  //Autocomplete Delete
  const onDeleteChangeHandler = (text) => {
    setdeletedtagselectedvalue(text);
    let match = [];
    if (text.length > 0) {
      match = alltagdata.filter(data => {
        const regex = new RegExp(`${text}`, "gi");
        return data.tag_name.match(regex);
      })
    }
    setSuggestionsDelete(match);
  }

  const onDeleteSuggestHandler = (text) => {
    console.log("text", text);
    setdeletedtagselectedvalue(text);
    setSuggestionsDelete([]);
    alltagdata.map((item, i) => {
      if (text === item.tag_name) {
        setoldtagId(item.id);
        setdeletedtagselectedvalue(item.tag_name);
        console.log(item.id, "iddddd", item.tag_name);
      }
    });
  }

  return (
    <>
      <DashboardTopBar />
      <CelebrityManagementSidebar handleView={handleCelebrityManagementView} history={props.history} />
      <div className="eventWrapper col-lg-12">
        <div className="card" style={{ width: "calc(100% - 240px)" }}>
          {page_pass ? (
            <div className="card-body">
              <div className="row mb-4" style={{ "marginTop": "-40px" }}>
                <div className="col-md-12 col-12">
                  <h1>{t("Tags Management")} </h1>
                </div>
              </div>
              <div className="row">
                <div className="col-md-3 col-12">
                  <label>New tag name:</label>
                </div>
                <div className="col-md-3 col-12">
                </div>
              </div>
              <div className="row mb-4">
                <div className="col-md-3 col-12">
                  <input type="text" className="form-control" name="tagName" placeholder="Enter Tag Name"
                    value={tagName} onChange={(e) => settagName(e.target.value)}></input>

                </div>
                <div className="col-md-3 col-12">
                  <button type="button" className="btn btn-theme btn-lg" onClick={InsertTagName}>
                    {t("Save")}
                  </button>
                </div>
              </div>
              <div className="row">
                <div className="col-md-3 col-12">
                  <label>Tag to be updated:</label>
                </div>
                <div className="col-md-3 col-12">
                  <label>Updated tag name:</label>
                </div>
              </div>
              <div className="row mb-4">
                <div className="col-md-3 col-12">
                  {/*<div className="autocompleteWrapper">*/}
                  {/*  <input type="text" className="form-control col-md-12 autocomplete"*/}
                  {/*    onChange={e => onChangeHandler(e.target.value)}*/}
                  {/*    value={text}*/}
                  {/*    placeholder="Enter Tag"*/}
                  {/*  />*/}
                  {/*  <ul className="suggestionWrapper" style={{ "z-index": "99" }}>*/}
                  {/*    {suggestions && suggestions.map((suggestion, i) =>*/}
                  {/*      <li className="suggestion col-md-12 justify-content-md-center"*/}
                  {/*        onClick={() => onSuggestHandler(suggestion.tag_name)}>{suggestion.tag_name}</li>*/}
                  {/*    )}*/}
                  {/*  </ul>*/}
                  {/*</div>*/}
                  <TagAutosuggest tag_name = {text} index = "-1" setLocation={(id,val)=>{setoldtagId(id);settagselectedvalue(val);setText(val);}}/>
                </div>
                <div className="col-md-3 col-12">
                  <input type="text" className="form-control" name="updatedtagName" placeholder="Enter Tag"
                    value={updatedtagName} onChange={(e) => setupdatedtagName(e.target.value)}></input>
                </div>
                <div className="col-md-3 col-12">
                  <button type="button" className="btn btn-update"
                    onClick={UpdateTags}
                  >
                    {t("Update")}
                  </button>
                </div>
              </div>
              <div className="row">
                <div className="col-md-3 col-12">
                  <label>Tag to be deleted:</label>
                </div>
                <div className="col-md-3 col-12">
                </div>
              </div>
              <div className="row mb-4">
                <div className="col-md-3 col-12">
                  {/* <div className="autocompleteWrapper">*/}
                  {/*  <input type="text" className="form-control col-md-12 autocomplete"*/}
                  {/*    onChange={e => onDeleteChangeHandler(e.target.value)}*/}
                  {/*    value={deletedtagselectedvalue}*/}
                  {/*    placeholder="Enter Deleted Tag"*/}
                  {/*  // onBlur={() => setSuggestionsDelete([])}*/}
                  {/*  />*/}
                  {/*  <ul className="suggestionWrapper" style={{ "z-index": "99" }}>*/}
                  {/*    {suggestionsDelete && suggestionsDelete.map((suggestion, i) =>*/}
                  {/*      <li key={suggestion.id} className="suggestion col-md-12 justify-content-md-center"*/}
                  {/*        onClick={() => onDeleteSuggestHandler(suggestion.tag_name)}>{suggestion.tag_name}</li>*/}
                  {/*    )}*/}
                  {/*  </ul>*/}
                  {/*</div>*/}
                  <TagAutosuggest tag_name = {deletedtagselectedvalue} index={"-1"} setLocation = {(id,val)=>{setoldtagId(id);setdeletedtagselectedvalue(val)}}/>
                </div>
                <div className="col-md-3 col-12">
                  <button type="button" className="btn btn-delete" onClick={DeleteTag}>
                    {t("Delete")}
                  </button>
                </div>
              </div>
            </div>
          ) : (
            <>
              <div className="card-body">
                <div className="row mb-4">
                  <div className="col-md-12 col-12">
                    <div className="col-md-8 col-12">
                      <label>Enter Password to access this section </label>
                      <div className="input-group mb-3">
                        <input
                          type="password"
                          value={passinput}
                          onChange={(event) => setpassInput(event.target.value)}
                          className="form-control"
                          placeholder="enter security password "
                        />
                        <div className="input-group-append">
                          <button
                            className="btn btn-outline-warning"
                            type="button"
                            onClick={pass_click}
                          >
                            Submit Password
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};
export default Tags;